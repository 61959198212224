import React from 'react'

import Layout from '../../components/Layout'
import Food from '../../components/Food'

export default function FoodPage() {
  return (
    <Layout>
      <Food />
    </Layout>
  )
}
