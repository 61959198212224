import React, { useState } from 'react'
import { useMeasure } from 'react-use'
import { max, rollup, sum } from 'd3-array'
import tw, { theme } from 'twin.macro'

import OverviewHeader from '../OverviewHeader'
import CockpitList from '../CockpitList'
import DownloadCard from '../DownloadCard'
import BarStackChart from '../BarStackChart'
import ChartToggle from '../ChartToggle'
import BarGroupLineChart from '../BarGroupLineChart'
import useData from '../../../src/data/useData'

const CHART_BOTTOM_OFFSET = 90
const margin = {
  top: 0,
  left: 70,
  right: 20,
  bottom: 20,
}

const X_PROP = 'date'
const WASTE = 'g waste/meal'
const CONSUMPTION = 'g meat/meal'
const MEAL_COUNT = 'meal count'

function getMeatConsumptionData(food, toggleWeight = true) {
  const data = food.map((d) => ({
    date: d.year,
    location: d.site,
    consumption: getConsumption(toggleWeight, d),
  }))

  return data
}

function getConsumption(toggleWeight, data) {
  if (toggleWeight) {
    return (data[CONSUMPTION] * data[MEAL_COUNT]) / 1e6
  }

  return data[MEAL_COUNT]
}

function getMeatPerMealData(food) {
  const data = food.map((d) => ({
    date: d.year,
    location: d.site,
    consumption: d[CONSUMPTION],
  }))

  const mealCountData = food.map((d) => ({
    date: d.year,
    toSum: d[CONSUMPTION],
    mealCount: d[MEAL_COUNT],
  }))

  const weightedAvgSeries = getWeightedAvgSeries(mealCountData)

  return [data, weightedAvgSeries]
}

const getFoodWithWaste = (food) => food.filter((d) => d.hasOwnProperty(WASTE))

function getFoodWasteData(food) {
  const data = getFoodWithWaste(food).map((d) => {
    return {
      date: d.year,
      location: d.site,
      waste: getWaste(d),
    }
  })

  return data
}

function getWaste(data) {
  return (data[WASTE] * data[MEAL_COUNT]) / 1e6
}

function getWastePerMealData(food) {
  const foodWithWaste = getFoodWithWaste(food)

  const data = foodWithWaste.map((d) => ({
    date: d.year,
    location: d.site,
    waste: d[WASTE],
  }))

  const mealCountData = foodWithWaste.map((d) => ({
    date: d.year,
    toSum: d[WASTE],
    mealCount: d[MEAL_COUNT],
  }))

  const weightedAvgSeries = getWeightedAvgSeries(mealCountData)

  return [data, weightedAvgSeries]
}

function getWeightedAvgSeries(data) {
  const summedData = Object.values(
    data.reduce((acc, curr) => {
      const { date, toSum, mealCount } = curr

      const value = toSum * mealCount
      const sum = acc?.[date]?.sum ? acc[date].sum + value : value
      const mealCountInYear = acc?.[date]?.mealCountInYear
        ? acc[date].mealCountInYear + mealCount
        : mealCount

      return {
        ...acc,
        [date]: {
          ...acc[date],
          date,
          sum,
          mealCountInYear,
        },
      }
    }, {})
  )

  const weightedAvgSeries = summedData.map((d) => ({
    date: d.date,
    weightedAvg: d.sum / d.mealCountInYear,
  }))

  return weightedAvgSeries
}

const getMaxYValue = (food) => {
  const values = rollup(
    getMeatConsumptionData(food),
    (v) => sum(v, meatConsumptionAccessors.y),
    meatConsumptionAccessors.x
  )
  return max(values, (d) => d[1])
}

const getTickFormat = (toggleY) => (toggleY ? '' : '~s')
const getTooltipFormat = (toggleY) => (toggleY ? '.1f' : '.3s')

const meatConsumptionAccessors = {
  x: (d) => d.date,
  y: (d) => d.consumption,
  z: (d) => d.location,
}

const foodWasteAccessors = {
  x: (d) => d.date,
  y: (d) => d.waste,
  z: (d) => d.location,
}

export default function Food() {
  const { food } = useData()
  const [c1Ref, { width: c1Width, height: c1Height }] = useMeasure()
  const [toggleWeight, setToggleWeight] = useState(true)

  return (
    <div tw="flex-1 lg:grid lg:grid-cols-4 lg:overflow-x-auto">
      <div tw="lg:col-span-3 lg:grid lg:grid-cols-2">
        <CockpitList tw="overflow-hidden">
          <div
            css={[
              `height: calc((100vh - ${theme`spacing.20`}) / 2);`,
              tw`flex flex-col`,
            ]}
            ref={c1Ref}
          >
            <OverviewHeader
              title={<>Meat consumption ({unit(toggleWeight)})</>}
            />
            <div tw="flex-1 h-0">
              {c1Height && (
                <BarStackChart
                  width={c1Width}
                  height={c1Height - CHART_BOTTOM_OFFSET}
                  data={getMeatConsumptionData(food, toggleWeight)}
                  accessors={meatConsumptionAccessors}
                  xProp={X_PROP}
                  unit={unit(toggleWeight)}
                  toggle={
                    <FoodToggle
                      toggle={toggleWeight}
                      setToggle={setToggleWeight}
                    />
                  }
                  tooltipFormat={getTooltipFormat(toggleWeight)}
                  tickFormat={getTickFormat(toggleWeight)}
                  increaseDomainByValue={1.2}
                  tw="px-3 pt-4 pb-3"
                />
              )}
            </div>
          </div>
          <div>
            <OverviewHeader
              title={
                <>Meat and fish consumption in the canteens (g meat/meal)</>
              }
            />
            {c1Height && (
              <BarGroupLineChart
                width={c1Width}
                height={c1Height - CHART_BOTTOM_OFFSET}
                margin={margin}
                data={getMeatPerMealData(food)}
                accessors={meatConsumptionAccessors}
                xProp={X_PROP}
                increaseDomainByValue={1.4}
                lineYAccessor={(d) => d.weightedAvg}
                unit="g meat/meal"
                tw="px-3 pt-4 pb-3"
              />
            )}
          </div>
        </CockpitList>

        <CockpitList tw="overflow-hidden">
          <div
            css={[
              `height: calc((100vh - ${theme`spacing.20`}) / 2);`,
              tw`flex flex-col`,
            ]}
          >
            <OverviewHeader title={<>Food waste (t)</>} />
            <div tw="flex-1 h-0">
              {c1Height && (
                <BarStackChart
                  width={c1Width}
                  height={c1Height - CHART_BOTTOM_OFFSET}
                  margin={margin}
                  data={getFoodWasteData(food)}
                  accessors={foodWasteAccessors}
                  xProp={X_PROP}
                  maxYValue={getMaxYValue(food)}
                  unit="t"
                  tw="px-3 pt-4 pb-3"
                />
              )}
            </div>
          </div>
          <div>
            <OverviewHeader
              title={<>Food waste in the canteens (g/main meal)</>}
            />
            {c1Height && (
              <BarGroupLineChart
                width={c1Width}
                height={c1Height - CHART_BOTTOM_OFFSET}
                margin={margin}
                data={getWastePerMealData(food)}
                accessors={foodWasteAccessors}
                xProp={X_PROP}
                lineYAccessor={(d) => d.weightedAvg}
                increaseDomainByValue={1.4}
                unit="g waste/meal"
                tw="px-3 pt-4 pb-3"
              />
            )}
          </div>
        </CockpitList>
      </div>

      <CockpitList tw="bg-grey-6">
        <OverviewHeader title="EXPLORE" />
        <DownloadCard field="food" title="Food" />
      </CockpitList>
    </div>
  )
}

function unit(toggleY) {
  return toggleY ? 't' : 'meals'
}

function FoodToggle({ toggle, setToggle }) {
  return (
    <ChartToggle
      selected={toggle}
      onChange={() => setToggle((prev) => !prev)}
      rightLabel="Weight (t)"
      leftLabel="Meal Count"
    />
  )
}
